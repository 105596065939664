<template>
  <el-form v-loading="loading" ref="form" :model="form" label-width="110px">
    <el-form-item label="活动名称">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <el-form-item label="活动时间">
      <el-date-picker v-model="form.time_range" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
    </el-form-item>
    <el-form-item label="购买支付金额">
      <el-input v-model="form.fee" style="width: 25rem">
        <template #append>元</template>
      </el-input>
    </el-form-item>
    <el-form-item label="卡券领取">
<!--      <el-radio v-model="form.coupon_func" :label="1">自动领取</el-radio>-->
      <el-radio v-model="form.coupon_func" :label="2">手动领取</el-radio>
      <el-radio v-model="form.coupon_func" :label="3">审核发放</el-radio>
    </el-form-item>
    <el-form-item label="首页展示">
      <el-radio v-model="form.index_show" :label="1">关闭</el-radio>
      <el-radio v-model="form.index_show" :label="2">开启</el-radio>
    </el-form-item>
    <el-form-item label="禁止无上级支付">
      <el-switch v-model="form.pay_need_has_from_uid"></el-switch>
    </el-form-item>
    <el-form-item align="right">
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "mode1",
  props:{
    active_id:{
      type:Number,
      default:0,
    }
  },
  watch:{
    active_id(e) {
      if(e > 0){
        this.edit_id = e;
        this.load()
      }
    },
  },
  mounted() {
    if(this.active_id > 0){
      this.edit_id = this.active_id;
      this.load();
    }
  },
  data(){
    return{
      edit_id:0,
      form:{
        mode:1,
        title:"",
        time_range:[],
        time_start:"",
        time_end:"",
        fee:0,
        coupon_func:2,
        index_show:1,
        pay_need_has_from_uid:false,
      },
      loading:false,
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        res.time_range = [res.time_start,res.time_end];
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit(){
      this.form.time_start = this.form.time_range[0];
      this.form.time_end = this.form.time_range[1];
      this.form.fee = parseFloat(this.form.fee);
      if (!this.form.id){
        this.$api.active.add(this.form).then((res)=>{
          this.$message.success("操作成功");
          this.$emit("next",res)
        })
        return;
      }
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>